import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toastifyError, toastifySuccess } from '../Utility/Utility';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';


const AstrologerList = () => {
    const isLogin = sessionStorage.getItem('IsLogin') ? sessionStorage.getItem('IsLogin') === 'true' || sessionStorage.getItem('IsLogin') === true : false;


    const [value, setvalue] = useState({ UserID: '', password: '' });
    const [error, setErrors] = useState('');
    const [AstrologerData, setAstrologerdata] = useState()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setvalue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        if (value?.UserID === 'Admin' || value?.password === '12345') {
            sessionStorage.setItem('IsLogin', true);
            toastifySuccess("Successfully LogIn");
        } else {
            toastifyError('Check UserID And Password')
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            checkValidationErrors();
        }
    };

    const checkValidationErrors = () => {
        const newErrors = {};
        if (!value?.UserID) {
            newErrors.UserID = 'required *';
        } else if (value?.UserID !== 'Admin') {
            newErrors.UserID = 'Check UserID *';
        }
        if (!value?.password) {
            newErrors.password = 'required *';
        // } else if (value?.password !== '12345') {
        } else if (value?.password !== 'MS79@arustu624MP') {
            newErrors.password = 'Check Password *';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            handleSubmit();
        }
    };

    const HandleChangeInput = (e) => {
        if (e.target.name === 'password') {
            let ele = e.target.value.replace(/[^0-9]/g, "");
            setvalue({
                ...value,
                [e.target.name]: ele
            });
        }
    }

    useEffect(() => {
        Get_Data_astrologer()
    }, []);

    const Get_Data_astrologer = async () => {
        const val = { 'IsActive': '1' };
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/Astrologer/GetData_Astrologer', val);
            const { data } = res
            const parseData = JSON.parse(data?.data)
            const Resdata = parseData?.Table
            if (Resdata) {
                setAstrologerdata(Resdata)
            }
        } catch (error) {
            console.log(error, 'error');
        }
    };


    return (
        <>
            {
                isLogin ?
                    <div>
                        <div className="container mt-5">
                            <h1 className="text-center mb-4">Astrologer List</h1>
                            <table className="table table-striped table-bordered ">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>DisplayName</th>
                                        <th>RegMobileNo</th>
                                        <th>EmailID</th>
                                        <th>Qualification</th>
                                        <th>Gender</th>
                                        <th>Experience (Years)</th>
                                        <th>Created Date</th>
                                        <th>City</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AstrologerData?.map((item) => (
                                        <tr key={item?.id}>
                                            <td className=" px- flex  items-center"> {item?.DisplayName}</td>
                                            <td>{item?.RegMobileNo}</td>
                                            <td>{item?.EmailID}</td>
                                            <td>{item?.HighestQualification}</td>
                                            <td>{item?.Gender}</td>
                                            <td>{item?.ExperiencedYears}</td>
                                            <td>{format(new Date(item?.CreatedDtTm), 'MMMM d, yyyy')}</td>
                                            <td>{item?.City}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className="container d-flex justify-content-center align-items-center vh-100">
                        <div className="card p-4 shadow-sm" style={{ width: '350px' }}>
                            <h3 className="text-center mb-4">Login</h3>
                            <div className="mb-3">
                                <label htmlFor="UserID" className="form-label">UserID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="UserID"
                                    name="UserID"
                                    value={value?.UserID}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Enter your UserID"
                                />
                                {error.UserID && <p style={{ color: 'red' }}>{error.UserID}</p>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={value?.password}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    maxLength={16}
                                    placeholder="Enter your password"
                                />
                                {error.password && <p style={{ color: 'red' }}>{error.password}</p>}
                            </div>
                            <button type="submit" className="btn btn-primary w-100" onClick={checkValidationErrors} >Login</button>
                        </div>
                    </div>
            }
        </>
    );
}

export default AstrologerList;
