import React, { useEffect } from 'react';
import AstroRegister from './AstroRegister/AstroRegister';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AstrologerList from './ListAstrologer/AstrologerList';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // if (location.pathname.endsWith("http://localhost:3000/")) {
    if (location.pathname.endsWith("https://astrocall.live/")) {
      navigate("/arustu");
    }
  }, [location, navigate]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<AstroRegister />} />
        <Route path="/arustu" element={<AstrologerList />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
